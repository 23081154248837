import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import styled from "styled-components";
import StyledH1 from "../styles/StyledH1";

const PostWrapper = styled.article`
  max-width: 45rem;
  margin: 60px auto;
  padding: 15px;
  a {
    color: ${props => props.theme.red};
  }
`;

export default function Contact() {
  return (
    <Layout grayFooter={true}>

      <PostWrapper>
        <StyledH1>Contact</StyledH1>

        <p>The best way to reach us is to use the form below. You can also reach us at <a
          href={'mailto:info@cleancatalog.com'}>info@cleancatalog.com</a> or <a
          href={"tel:1-800-648-7146"}>1-800-648-7146</a>.</p>

        <h2>Address</h2>
        <p>Our address, if you need it:</p>
        <address>
          Clean Catalog<br/>
          1752 NW Market Street #4305<br/>
          Seattle, WA 98107
        </address>
      </PostWrapper>
    </Layout>
  )
}

export function Head(props) {
  return (
    <Seo
      title="Contact"
      description='Contact us about moving to a course catalog management system.'
      canonical={props.location.pathname}
    />
  )
}

